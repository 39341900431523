@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input::placeholder {
  color: rgb(75, 75, 75) !important;
  padding-left: 5px;
  font-size: small;
  font-weight: 400;
}
.loginRightSection {
  background-image: url("./assets/login_bg.webp");
}

.btn {
  @apply text-16px-vw rounded-full text-white font-poppins font-medium py-3 px-8 min-w-[100px] h-[52px] gap-4;
}
.btn-primary {
  @apply bg-primary-400;
}
.btn-secondary {
  @apply bg-primary-300;
}
.btn-outline-white {
  @apply border border-white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #f9f9fb; */
}
/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 0.5vw;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d1d5db; /* Tailwind gray-300 */
  border-radius: 10px;
  border: 2px solid #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #9ca3af; /* Tailwind gray-500 */
}
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


